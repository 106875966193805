"use client"

import { useState } from "react"
import { Icon } from "@/components/ui/icon"
import {
  Sheet,
  SheetContent,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet"
import Link from "next/link"
import type { getDictionary } from "@/get-dictionary"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import {
  affiliationMap,
  type Affiliation,
  affiliations,
} from "@/affiliations-config"
import { Separator } from "@/components/ui/separator"
import type { SpecialityType } from "@/models/Speciality"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

type Props = {
  dictionary: Awaited<ReturnType<typeof getDictionary>>["header"]
  specialities: SpecialityType[]
  affiliation: Affiliation
}

export function MobileNav({ dictionary, specialities, affiliation }: Props) {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <div className="container flex items-center justify-between py-4 sm:hidden">
      <Link href={`/`}>
        <Icon name="app--sterlings-logo" className="h-9 w-36" />
      </Link>
      <div className="flex items-center gap-3">
        <Popover>
          <PopoverTrigger>
            <Icon name="app--ambulance" className="h-9 w-9" />
          </PopoverTrigger>
          <PopoverContent className="p-0">
            <ul className="list-none">
              {Array.from(affiliationMap.entries())
                .filter(([key]) => key !== affiliations.defaultHospital)
                .map(([key, value]) => (
                  <li
                    key={key}
                    className="flex items-center justify-between border-b p-4 text-sm font-medium hover:bg-accent"
                  >
                    <span>{value.name}</span>
                    <a href={`tel:${value.tel}`}>
                      <Icon name="app--call" className="h-6 w-5 text-red-500" />
                    </a>
                  </li>
                ))}
            </ul>
          </PopoverContent>
        </Popover>

        <Sheet open={open} onOpenChange={setOpen}>
          <SheetTrigger asChild>
            <div className="flex items-center">
              <Icon name="heroicons--bars-3-solid" className="h-8 w-8" />
            </div>
          </SheetTrigger>
          <SheetContent side="left" className="w-full p-0">
            <SheetTitle className="border-b p-4">
              <Link href={`/`}>
                <Icon
                  name="app--sterlings-logo"
                  className="h-8 w-32"
                  onClick={() => setOpen(false)}
                />
              </Link>
            </SheetTitle>
            <Accordion type="single" collapsible className="w-full p-4">
              <Link
                href={`/${affiliation}/aboutus`}
                onClick={() => setOpen(false)}
              >
                <AccordionItem value="aboutus">
                  <AccordionTrigger showIcon={false}>
                    {dictionary._about_us}
                  </AccordionTrigger>
                </AccordionItem>
              </Link>
              <AccordionItem value="hospitals">
                <AccordionTrigger>{dictionary._our_hospitals}</AccordionTrigger>
                <AccordionContent>
                  <ul className="flex w-full list-none flex-col">
                    {Array.from(affiliationMap.entries())
                      .filter(
                        ([key, value]) => key !== affiliations.defaultHospital
                      )
                      .map(([key, value]) => (
                        <Link
                          key={key}
                          href={`/${key}`}
                          onClick={() => setOpen(false)}
                        >
                          <Separator />
                          <li className="py-2 text-brand-500">{value.name}</li>
                        </Link>
                      ))}
                  </ul>
                </AccordionContent>
              </AccordionItem>
              <Link
                href={`/${affiliation}/doctors`}
                onClick={() => setOpen(false)}
              >
                <AccordionItem value="doctors">
                  <AccordionTrigger showIcon={false}>
                    {dictionary._doctors}
                  </AccordionTrigger>
                </AccordionItem>
              </Link>
              <AccordionItem value="specialities">
                <AccordionTrigger>{dictionary._specilities}</AccordionTrigger>
                <AccordionContent>
                  <ul className="flex max-h-64 w-full list-none flex-col overflow-y-auto">
                    {specialities
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((speciality) => (
                        <Link
                          key={speciality.uid}
                          href={`/${affiliation}/specialities/${speciality.uid}`}
                          onClick={() => setOpen(false)}
                        >
                          <Separator />
                          <li className="py-2 text-brand-500">
                            {speciality.name}
                          </li>
                        </Link>
                      ))}
                  </ul>
                </AccordionContent>
              </AccordionItem>
              <Link
                href={`/${affiliation}/health-checks`}
                onClick={() => setOpen(false)}
              >
                <AccordionItem value="health-packages">
                  <AccordionTrigger showIcon={false}>
                    {dictionary._health_checkup}
                  </AccordionTrigger>
                </AccordionItem>
              </Link>
              <Link
                href={`/lifesaver`}
                onClick={() => setOpen(false)}
              >
                <AccordionItem value="lifesaver">
                  <AccordionTrigger showIcon={false}>
                    {dictionary._lifesaver}
                  </AccordionTrigger>
                </AccordionItem>
              </Link>
              <Link
                href={`/international-patients`}
                onClick={() => setOpen(false)}
              >
                <AccordionItem value="patients">
                  <AccordionTrigger showIcon={false}>
                    {dictionary._international_patients}
                  </AccordionTrigger>
                </AccordionItem>
              </Link>
              <Link href={`/success-stories`} onClick={() => setOpen(false)}>
                <AccordionItem value="success-stories">
                  <AccordionTrigger showIcon={false}>
                    {dictionary._success_stories}
                  </AccordionTrigger>
                </AccordionItem>
              </Link>
              <Link href={`/csr`} onClick={() => setOpen(false)}>
                <AccordionItem value="csr">
                  <AccordionTrigger showIcon={false}>
                    {dictionary._csr}
                  </AccordionTrigger>
                </AccordionItem>
              </Link>
              <Link href={`/josh`} onClick={() => setOpen(false)}>
                <AccordionItem value="josh">
                  <AccordionTrigger showIcon={false}>
                    {dictionary._josh}
                  </AccordionTrigger>
                </AccordionItem>
              </Link>
            </Accordion>
          </SheetContent>
        </Sheet>
      </div>
    </div>
  )
}
