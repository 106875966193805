"use client"

import { affiliationMap, affiliations } from "@/affiliations-config"
import { Icon } from "@/components/ui/icon"
import React, { useState } from "react"

const Emergency = () => {
  const [expanded, setExpanded] = useState(false)
  const hospitals = [
    { name: "Hospital 1", number: "1234567890" },
    { name: "Hospital 2", number: "0987654321" },
    // Add more hospitals as needed
  ]

  return (
    <div
      className={
        "fixed left-0 top-1/2 z-100 hidden w-auto -translate-y-1/2 transform cursor-pointer rounded-r-lg bg-red-500 py-2 font-bold text-white sm:block"
      }
      onClick={() => setExpanded(!expanded)}
    >
      <div className="flex">
        <div
          className="flex items-center justify-center"
          style={{ writingMode: "vertical-rl", textOrientation: "upright" }}
        >
          EMERGENCY
        </div>
        {expanded && (
          <div className="relative flex-grow space-y-4 p-4">
            <button
              className="absolute right-0 top-0 cursor-pointer rounded-l-lg bg-red-500 text-white"
              onClick={() => setExpanded(false)}
            >
              <Icon name="heroicons--x-mark-solid" className="h-6 w-6" />
            </button>
            {Array.from(affiliationMap.entries())
              .filter(([key]) => key !== affiliations.defaultHospital)
              .map(([key, value]) => (
                <div key={key} className="flex justify-between pl-4 text-base">
                  <div>{value.name}</div>
                  <div className="ml-4">
                    <a href={`tel:${value.tel}`} className="underline">
                      {value.tel}
                    </a>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Emergency
