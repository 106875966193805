"use client"

import Link from "next/link"
import type { getDictionary } from "@/get-dictionary"
import { Icon } from "@/components/ui/icon"
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu"
import React from "react"
import { Separator } from "@/components/ui/separator"
import type { SpecialityType } from "@/models/Speciality"
import {
  affiliationMap,
  type Affiliation,
  affiliations,
} from "@/affiliations-config"

type Props = {
  dictionary: Awaited<ReturnType<typeof getDictionary>>["header"]
  specialities: SpecialityType[]
  affiliation: Affiliation
}

export function MainNav({ dictionary, affiliation, specialities }: Props) {
  return (
    <div className="hidden sm:flex sm:flex-col">
      {/* Top Nav Header */}
      <div className="bg-brand-500">
        <div className="container flex h-9 w-full items-center justify-end gap-4 text-xs text-secondary ">
          <Link href="/aboutus">{dictionary._about_us}</Link>
          <Link href="/josh">{dictionary._josh}</Link>
          <Link href="/success-stories">{dictionary._success_stories}</Link>
          <Link href="/csr">{dictionary._csr}</Link>
          <Link href="/blogs">{dictionary._blogs}</Link>
        </div>
      </div>
      <div className="flex h-32 items-center">
        <div className="container flex h-16 justify-between">
          <Link href={`/`}>
            <Icon name="app--sterlings-logo" className="h-16 w-56 lg:w-64" />
          </Link>
          <NavigationMenu className="">
            <NavigationMenuList className="space-x-0">
              <NavigationMenuItem>
                <NavigationMenuTrigger>
                  {dictionary._our_hospitals}
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="grid w-[400px] list-none gap-4 p-4 lg:w-[700px] lg:grid-cols-2 xl:w-[900px] xl:grid-cols-3">
                    {Array.from(affiliationMap.entries())
                      .filter(
                        ([key, value]) => key !== affiliations.defaultHospital
                      )
                      .map(([key, value]) => (
                        <Link key={key} href={`/${key}`}>
                          <li className="p-4 text-brand-500">{value.name}</li>
                          <Separator />
                        </Link>
                      ))}
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <Link href={`/${affiliation}/doctors`} legacyBehavior passHref>
                  <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                    {dictionary._doctors}
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <NavigationMenuTrigger>
                  {dictionary._specilities}
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="grid h-96 w-[400px] list-none gap-4 overflow-x-auto p-4 lg:w-[700px] lg:grid-cols-2 xl:w-[900px] xl:grid-cols-3">
                    {specialities
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((speciality) => (
                        <Link
                          key={speciality.uid}
                          href={`/${affiliation}/specialities/${speciality.uid}`}
                        >
                          <li className="col-span-1 break-words pb-2 text-brand-500">
                            {speciality.name}
                          </li>
                          <Separator />
                        </Link>
                      ))}
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <Link
                  href={`/${affiliation}/health-checks`}
                  legacyBehavior
                  passHref
                >
                  <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                    {dictionary._health_checkup}
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
              <NavigationMenuItem className="hidden lg:block">
                <Link href={`/lifesaver`} legacyBehavior passHref>
                  <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                    {dictionary._lifesaver}
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
              <NavigationMenuItem className="hidden xl:block">
                <Link href={`/international-patients`} legacyBehavior passHref>
                  <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                    {dictionary._international_patients}
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
              <NavigationMenuItem className="hidden xl:block">
                <Link
                  href={`/${affiliation}/contactus`}
                  legacyBehavior
                  passHref
                >
                  <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                    {dictionary._contact_us}
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>

          <div className="flex h-full items-center gap-4">
            <Link
              href="tel:98 98 98 78 78"
              className="flex h-12 items-center gap-2 rounded-lg border border-custom-10 px-2"
            >
              <Icon name="app--call" className="h-6 w-5 text-custom-10" />
              <span
                className="hidden truncate font-bold text-custom-10 lg:block"
                title="98 98 98 78 78"
              >
                98 98 98 78 78
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
